import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { QueryClientProvider, QueryClient } from "react-query";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "../src/v1.06/redux/store"; // Adjust the import path according to your structure

import PaymentForm from "./v1.06/screens/PaymentForm";
import HomeScreen from "./v1.06/screens/HomeScreen";
import LandingScreen from "./v1.06/screens/LandingScreen";
import SessionTokenValidate from "./v1.06/screens/SessionTokenValidate";
import AuthenticationScreen from "./v1.06/screens/AuthenticationScreen";
import CreditRentBoost from "./v1.06/screens/CreditRentBoost";
import Confirmation from "./v1.06/screens/Confirmation";
import GoalSubscription from "./v1.06/screens/GoalSubscription";
import AddPaymentMethod from "./v1.06/screens/AddPaymentMethod";
import BillingScreen from "./v1.06/screens/BillingScreen";
import SavePaymentCards from "./v1.06/screens/SavePaymentCards";

import GoalAdded from "./v1.06/screens/GoalAdded";
import ListPayments from "./v1.06/screens/ListPayments";
import GuacSlider from "./v1.06/screens/GuacSlider";
import PortFolioPricing from "./v1.06/screens/PortFolioPilot/PortFolioPricing";
import GuacScreen from "./v1.06/screens/PortFolioPilot/GuacScreen";
import PortfolioPayment from "./v1.06/screens/PortFolioPilot/PortfolioPayment/PortfolioPayment";
import AddressScreen from "./v1.06/screens/PortFolioPilot/Address/AddressScreen";
import AddPaymentMethodPortfolio from "./v1.06/screens/PortFolioPilot/AddPaymentMethodPortfolio/AddPaymentMethodPortfolio";
import AddPaymentCardsAch from "./v1.06/screens/PortFolioPilot/AddPaymentCardsAch/AddPaymentCardsAch";
import Authenticate from "./v1.06/screens/Authenticate/Authenticate";
import PortfolioGoal from "./v1.06/screens/PortFolioPilot/GoalAdded/PortfolioGoal";

const queryClient = new QueryClient();

function App() {
  const currentUrl = new URL(window.location.href);
  const path = currentUrl.pathname;

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<SessionTokenValidate />} />
              <Route path="/onegoal" element={<GoalSubscription />} />
              <Route path="/ListPay" element={<ListPayments />} />
              <Route path="/AddMethods" element={<AddPaymentMethod />} />
              <Route path="/PaymentOneGoal" element={<SavePaymentCards />} />
              <Route path="/Address" element={<BillingScreen />} />
              <Route path="/goalAdded" element={<GoalAdded />} />

              <Route path="/guac" element={<GuacSlider />} />

              <Route path="/portfoliopilot" element={<PortfolioPayment />} />
              <Route path="/AddressPortfolio" element={<AddressScreen />} />
              <Route
                path="/AddMethodsPortfolio"
                element={<AddPaymentMethodPortfolio />}
              />
              <Route
                path="/AddPaymentCardsAch"
                element={<AddPaymentCardsAch />}
              />

              <Route path="/authenticate" element={<Authenticate />} />
              <Route path="/portfolioGoal" element={<PortfolioGoal />} />

              <Route path="/crb" element={<LandingScreen />} />
              <Route path="/error" element={<SessionTokenValidate />} />
              <Route path="/auth" element={<AuthenticationScreen />} />
              <Route path="/confirmation" element={<CreditRentBoost />} />
              <Route path="/done" element={<Confirmation />} />
              <Route path="/home" element={<HomeScreen />} />
              <Route path="/payment" element={<PaymentForm />} />
            </Routes>
          </BrowserRouter>
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
