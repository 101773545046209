import React, {
  useEffect,
  useState,
  useCallback,
  useRef,
  useMemo,
} from "react";
import ImageTextSlider from "../components/pager";
import arrowBack from "../assets/Images/arrow_back_ios_new.png";
import flash from "../assets/Images/flash_white.png";
import like from "../assets/Images/like.png";
import building from "../assets/Images/apap.png";
import refresh from "../assets/Images/refresh.png";
import question from "../assets/Images/question.png";
import pwa_image from "../assets/Images/pwa_image.png";
import contract from "../assets/Images/contract.png";
import forward_arrow from "../assets/Images/forward_arrow.png";
import { makeApiCall } from "../services/ApiService";
import {
  API_CALL_TYPE,
  GET_BILLING_ADDRESSES,
  GET_SUBSCRIPTION_PLANS,
  POST_PAYMENT_METHOD,
  SAVE_SESSION,
  UPDATE_SESSION,
} from "../services/Api";
import { useQueryClient } from "react-query";
import VerifiedOutlinedIcon from "@mui/icons-material/VerifiedOutlined";
import StarRateIcon from "@mui/icons-material/StarRate";
import Swiper from "swiper";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch, useSelector } from "react-redux";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import {
  setSessionData,
  setLoading,
  setError,
  setClickedHeader,
  setDynamicLink,
  SessionState,
  setSessionIDStore,
  setAsaConsumerCode,
} from "../redux/sessionSlice";
import { SessionData, SessionUpdateRequest } from "../types/types";
import { ToastContentProps, toast } from "react-toastify";
import { Config } from "../Utils/AppConstants";
interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}

interface PaymentMethod {
  arrow_open: any;
  dropdown_arrow_close: any;
  paymentMethodId: number;
  asaPaymentMethodCode: number;
  isDefault: boolean;
  paymentMethodName: string;
  paymentType: string;
  routingNumber: string | null;
  last4DigitsOfAccountNumber: string | null;
  bankName: string;
  cardNumber: string | null;
  accountNumber: string | null;
  cardType: string | null;
  last4DigitsOfCardNumber: string | null;
  firstName: string | null;
  middleName: string | null;

  lastName: string | null;
  cardHolderName: string | null;
  expirationYear: string | null;
  expirationMonth: string | null;
}

const SkeletonLoader = () => (
  <div className="animate-pulse space-y-4">
    {/* Card 1 */}
    <div className="bg-gradient-to-r from-[#1B9C83] to-emerald-400 p-6 rounded-xl flex justify-between items-center">
      <div className="space-y-2">
        <div className="bg-gray-400 h-6 w-40 rounded"></div>
        <div className="bg-gray-400 h-4 w-60 rounded"></div>
      </div>
    </div>
    {/* Divider */}
  </div>
);

const GoalSubscription = () => {
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  const [plans, setPlans] = useState<any[] | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subscriptionType, setSubscriptionType] = useState("Yearly");
  const [subscriptionAmount, setsubscriptionAmount] = useState(0);
  const [showLoader, setShowLoader] = useState(false);
  const queryClient = useQueryClient();

  const [getSessionCalled, setGetSessionCalled] = useState(false);
  const swiperRef = useRef<HTMLDivElement | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const [createdSessionData, setcreatedSessionData] =
    useState<SessionData | null>(null);
  const [sessionID, setSessionID] = useState<string | null>(null);
  const [sessionFetched, setSessionFetched] = useState(false);
  const [showPlans, setshowPlans] = useState(false);
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");

  const storeSesssionId = useSelector(
    (state: SessionState) => state?.session?.sessionIDStore
  );
  var settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false
  ) => {
    const toastType = isError ? toast.error : toast.success;

    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543",
        color: "white",
        fontFamily: "Poppins",
      },
      bodyClassName: "poppins-font",
    });
  };

  // const UpdatedLocalData = useSelector(
  //   (state: SessionState) => state?.session.sessionData
  // );

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const slides = [
    {
      image: building,
      text: "See all your accounts in one place!",
    },
    {
      image: refresh,
      text: "Account balances sync with your bank automatically",
    },
    {
      image: contract,
      text: "Transactions are automatically sent to One Goal!",
    },
    {
      image: like,
      text: "Drag and drop to categorize transactions",
    },
    {
      image: question,
      text: "Get Priority Support",
    },
  ];

  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses

  const storedSessionId = useSelector(
    (state: SessionState) => state?.session.sessionIDStore
  );
  const storedConsumerCode = useSelector(
    (state: SessionState) => state?.session.asaConsumerCode
  );

  const handlePaymentMethodsResponse = async (data: any) => {
    const formattedPaymentMethods: PaymentMethod[] = data.data.map(
      (method: any) => ({
        paymentMethodId: method.paymentMethodId,
        asaPaymentMethodCode: method.asaPaymentMethodCode,
        isDefault: method.isDefault,
        paymentMethodName: method.paymentMethodName,
        paymentType: method.paymentType,
        routingNumber: method.routingNumber,
        last4DigitsOfAccountNumber: method.last4DigitsOfAccountNumber,
        bankName: method.bankName,
        cardType: method.cardType,
        cardNumber: method.cardNumber,
        accountNumber: method.accountNumber,
        last4DigitsOfCardNumber: method.last4DigitsOfCardNumber,
        firstName: method.firstName,
        middleName: method.middleName,
        lastName: method.lastName,
        cardHolderName: method.cardHolderName,
        expirationYear: method.expirationYear,
        expirationMonth: method.expirationMonth,
      })
    );

    setPaymentMethods(formattedPaymentMethods);

    try {
      // Set loading state to true before making the API call

      await updateSession(
        createdSessionData,
        formattedPaymentMethods,
        "3",
        "PaymentMethodList",
        "PaymentMethodList",
        "/PaymentList",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);

      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
  };

  const fetchPaymentMethods = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        POST_PAYMENT_METHOD(),
        handlePaymentMethodsResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleSessionResponse = async (response: any) => {
    setIsLoading(false);
    if (response && response.data && response.data.asaSessionCode) {
      queryClient.setQueryData("sessionData", response);
      setcreatedSessionData(response?.data);
      showCustomToast(response?.message, false);

      // Call fetchSubscriptionPlans here after successful session response
      // await fetchSubscriptionPlans();

      await Promise.all([
        fetchSubscriptionPlans(),
        fetchBillingAddresses(),
        fetchPaymentMethods(),
      ]);
      //await fetchBillingAddresses();
    } else if (response && response?.status == "400") {
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      setIsLoading(false);
    }
  };

  const handleSessionError = (error: any) => {
    navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
  };

  const getSession = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        SAVE_SESSION(Config.sessionID),
        handleSessionResponse,
        handleSessionError,
        "",
        "",
        ""
      );

      return response;
    } catch (error) {
      setIsLoading(false);

      if (!getSessionCalled) {
        setGetSessionCalled(true);
        getSession();
      }
      throw error;
    }
  }, []);

  const fetchSubscriptionPlans = useCallback(async () => {
    try {
      setIsLoadingAPI(true);
      setIsLoading(true);
      const response = await makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_SUBSCRIPTION_PLANS(),
        handleFetchPlansSuccess,
        handleFetchPlansError,
        "",
        ""
      );
    } catch (error) {
    } finally {
      setIsLoadingAPI(false);
      setIsLoading(false);
    }
  }, []);

  const updateSession = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-ONEGOAL",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID,

          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      const sessionID: string = queryClient.getQueryData("sessionID") as string;

      if (!sessionID) {
        throw new Error("Session ID not found");
      }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  const updateSessiongoal = async (
    _createdSessionDataifany: any,
    data: any,
    pageNUmber: string,
    pageName: string,
    pageTitle: string,
    pageDescription: string,
    pageUrl: string,
    pageStatusMessage: string
  ) => {
    dispatch(setLoading(true));
    setIsLoading(true);
    const updateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-ONEGOAL",
      pageCount: _createdSessionDataifany?.pageCount,
      status: "Incomplete",
      statusMessage: _createdSessionDataifany?.statusMessage,
      sessionPages: [
        {
          asaSessionCode: Config.sessionID, // Use the session ID
          pageNumber: pageNUmber, // Page number, could be a string or a number depending on your API requirements
          pageName: pageName, // Name of the page (e.g., "Landing")
          pageTitle: pageTitle, // Title of the page (e.g., "Select Package")
          pageDescription: pageDescription, // Description of the page
          pageUrl: pageUrl, // URL of the page
          pageStatus: "Complete", // Status of the page (e.g., "New")
          pageStatusMessage: pageStatusMessage, // Status message for the page
          pageData: JSON.stringify(data), // Serialize the selected plan data
        },
      ],
    };

    try {
      // const sessionID: string = queryClient.getQueryData("sessionID") as string;

      // if (!sessionID) {
      //   throw new Error("Session ID not found");
      // }

      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponsegoal,
        handleUpdateSessionError,
        "",
        "",
        updateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));

      throw error;
    }
  };
  // Handling Update Session response

  const handleUpdateSessionResponsegoal = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      navigate("/ListPay");

      // Dispatch actions, update state, etc.
    }
  };
  const handleUpdateSessionResponse1 = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // Handle the invalid session error here

      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/checkout", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases

      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);

      // Dispatch actions, update state, etc.
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const handleFetchPlansSuccess = async (data: any) => {
    if (data && data.data) {
      setPlans(data.data);

      await updateSession(
        createdSessionData,
        data.data,
        "1",
        "GOALS PLANS",
        "USER SELECTED PLAN",
        "/oneGoal",
        "INCOMPLETE",
        "INCOMPLETE"
      );
    } else {
      setPlans([]);
    }
  };

  const handleFetchPlansError = (error: any) => {};

  const handleBackClick = () => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData.data.sessionPages;
      let backActionDynamicLink = null;

      for (let i = 0; i < sessionPages.length; i++) {
        if (sessionPages[i].pageNumber === 0) {
          const pageData = JSON.parse(sessionPages[i].pageData);
          backActionDynamicLink = pageData.backActionDynamicLink;
          break;
        }
      }

      if (backActionDynamicLink) {
        window.location.href = backActionDynamicLink;
      } else {
        console.error("Back action dynamic link not found.");
      }
    } else {
      console.error("UpdatedLocalData is not available.");
    }
  };

  const updateSession1 = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,

    pageTitle: string
  ) => {
    dispatch(setLoading(true));
    //setIsLoading(true);

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: Config.sessionID,
      asaConsumerCode: Config.asaConsumerCode,

      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: session_if_any.pageCount,
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: updatedSessionData.statusMessage,
      sessionPages: [
        ...(Array.isArray(updatedSessionData.sessionPages)
          ? updatedSessionData.sessionPages
          : []),
        {
          asaSessionCode: Config.sessionID,
          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,
          pageDescription: pageTitle,
          pageUrl: "/PaymentList",
          pageStatus: "Completed",
          pageStatusMessage: "PaymentList",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse1,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      throw error;
    }
  };

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    setBillingAddresses(formattedBillingAddresses);

    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData;
      } else {
        dataToUpdate = updatedsessionData;
      }

      if (dataToUpdate) {
        await updateSession(
          createdSessionData,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList",
          "/oneGoal",
          "INCOMPLETE",
          "INCOMPLETE"
        );
        // Navigate to payment screen after successful API call
        // navigate("/payment");
      } else {
        console.error("No session data available to update.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }

    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    setIsLoading(false); // Stop loading state on error
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    // const currentUrl = new URL(Config.BROWSER_URL);
    const sessionID = currentUrl.searchParams.get("sessionid");
    const asaConsumerCode = currentUrl.searchParams.get("asaConsumerCode");
    const versionCode = currentUrl.searchParams.get("version");
    Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");
    const token = currentUrl.searchParams.get("token");
    const asaFicode = currentUrl.searchParams.get("asaFintechCode");
    // const asaFintechCode = currentUrl.searchParams.get("asaFintechCode");

    if (asaFicode !== null) {
      const parsedFicode = parseInt(asaFicode, 10);
      if (!isNaN(parsedFicode)) {
        Config.asaFicode = parsedFicode;
      } else {
        // Handle case where asaFicode is not a valid number
        console.error("Invalid asaFicode:", asaFicode);
      }
    }
    let decodedLink: string | null = null;
    const encodedLink = currentUrl.searchParams.get("dynamicLink");
    if (encodedLink !== null) {
      decodedLink = decodeURIComponent(encodedLink); // Assign decodedLink inside the if block
      dispatch(setDynamicLink(decodedLink)); // Dispatch action to save dynamic link
      queryClient.setQueryData("dynamicLink", decodedLink);
    } else {
      console.error("encodedLink is nulldsfdsfdsf");
    } // Declare decodedLink outside the if block
    if (sessionID && asaConsumerCode) {
      // Update Config object with session ID and subscription key
      Config.sessionID = sessionID;
      Config.asaConsumerCode = parseInt(asaConsumerCode);
      // Save session ID to React Query cache
      queryClient.setQueryData("sessionID", sessionID);
      setSessionID(sessionID);
      dispatch(setSessionIDStore(sessionID));
      dispatch(setAsaConsumerCode(Config.asaConsumerCode));

      // if (sessionID) {
      // If sessionID is not set, call getSession to fetch it
      setSessionFetched(true);
      getSession();

      // }
    } else {
      if (UpdatedLocalData) {
        Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
        Config.asaConsumerCode = parseInt(
          UpdatedLocalData?.data?.asaConsumerCode
        );

        queryClient.setQueryData("sessionID", Config.sessionID);
        setSessionID(Config.sessionID);
        dispatch(setSessionIDStore(UpdatedLocalData?.data?.asaSessionCode));
        dispatch(setAsaConsumerCode(Config.asaConsumerCode));

        // if (sessionID) {
        // If sessionID is not set, call getSession to fetch it
        setSessionFetched(true);
        getSession();
      }

      //navigate("/error", { state: "CRB AUTHENTICATION ERROR" });
    }
    // }, [sessionID, Config.asaConsumerCode, sessionFetched]); // Run whenever sessionID changes
  }, [showPlans]); // Run whenever sessionID changes

  const handlePlanClick = async (plan: any) => {
    setSubscriptionType(plan?.billingCycle);
    setsubscriptionAmount(plan?.oneTimeAmount);
    queryClient.setQueryData("selectedPlan", plan);
    setshowPlans(true);

    try {
      await updateSessiongoal(
        createdSessionData,
        plan,
        "2",
        "SUBSCRIPTION PLAN",
        "Select Package",
        "Select Package",
        "/onegoal",
        "INCOMPLETE"
      );
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error fetching session:"));
      console.error("Error fetching session:", error);
      throw error;
    }

    // Navigate to the next page or perform other actions
    // navigate("/ListPayments", { state: { plan } });
  };

  return (
    <div className="bg-gray-900 flex justify-center items-center min-h-screen">
      <div className="bg-gradient-to-r from-[#1B9C83] to-[#20BD9D] w-full h-screen overflow-y-auto text-white relative z-20">
        <div className="flex items-center justify-evenly space-x-8 pt-8 p-3">
          <div
            className="rounded-[10px] p-1 w-[70px] h-[45px] flex items-center justify-center"
            style={{
              backgroundColor: "#0000001A", // 10% black
              boxShadow: "0 4px 4px 0 rgba(0, 0, 0, 0.25)",
            }}
          >
            <img
              className="cursor-pointer w-[20px] h-[20px]"
              src={arrowBack}
              onClick={handleBackClick}
              alt="Back"
            />
          </div>

          <h1 className="font-poppins ml-3  text-[22px] leading-[28px] tracking-[1px] w-[500px] h-[56px] mt-6 ">
            Love & Money, Finally
            <br /> in Sync.
          </h1>

          {/* <img className="h-16 w-16 mx-6" src={pwa_image} alt="PWA" /> */}
        </div>

        <img
          src={flash}
          className="relative top-2 left-5 z-50 w-[65px] h-[65px]"
          alt="Flash"
        />

        <div className="bg-[#EBECF0] top-8 rounded-more text-gray-800 mx-auto rounded-t-[3rem] absolute bottom-0 left-0 right-0 h-full overflow-y-auto mt-24">
          <div className="h-full p-6 shadow-lg">
            <div className="flex items-center mb-2">
              <img
                src={flash}
                className="relative -top-2 hidden left-5 z-50 w-[80px] h-[80px]"
                alt="Flash"
              />
              <span className="font-semibold relative -top-4 font-poppins left-20 text-[18px]">
                One Goal
              </span>
            </div>

            <div className="border-[3.91px] border-[#1B9C83] rounded-xl">
              <div className="rounded-xl bg-[#c9eae5] p">
                {/* <button
                  style={{ letterSpacing: "2px" }}
                  className="bg-[#96d9cd] border-[#1B9C83] border text-black relative top-3 left-5 px-3 py-0 rounded-full border-b-4 font-poppins text-[10px] font-bold mb-4"
                >
                  SUBSCRIBE
                </button> */}

                {/* 
                <button className="bg-[#96d9cd] border-[#1B9C83]  relative top-5 left-5 px-3 py-1 tracking-widest rounded-full border-b-4 text-sm font-bold mb-6">
                  SUBSCRIBE
                </button> */}

                <button
                  className="bg-[#96d9cd] border-[#1B9C83] border text-black relative top-2 left-3 px-2 py-1 tracking-widest rounded-full border-b-4 text-xs mb-6"
                  style={{
                    fontSize: "12px", // Adjust the font size to make the text smaller
                    fontWeight: 600,
                    letterSpacing: "0.15em",
                    textAlign: "center",
                  }}
                >
                  SUBSCRIBE
                </button>

                <ImageTextSlider slides={slides} />
              </div>

              <div className="space-y-3 mt-6 p-2 bg-white rounded-xl">
                {/* Conditional rendering based on isLoading and plans */}
                {isLoading || plans === null ? (
                  // Show skeleton loader while loading or plans are null
                  <>
                    <SkeletonLoader />
                    <SkeletonLoader />
                    <SkeletonLoader />
                  </>
                ) : plans.length > 0 ? (
                  // Render subscription options dynamically
                  plans.map((plan) => (
                    <button
                      key={plan.subscriptionPlanId}
                      onClick={() => handlePlanClick(plan)}
                      className="w-full bg-gradient-to-r from-[#1B9C83] to-[#3aa690] text-white p-4 rounded-xl text-left flex justify-between items-center"
                    >
                      <div>
                        <div
                          className="font-poppins"
                          style={{
                            fontWeight: 600,
                            fontSize: "16px",
                            lineHeight: "20px",
                            letterSpacing: "-0.1px",
                          }}
                        >
                          {plan.billingCycle === "Monthly"
                            ? `$${plan?.recurringAmount} / month`
                            : `$${plan?.recurringAmount} / year`}
                        </div>
                        <div
                          className="font-medium font-poppins text-white"
                          style={{
                            fontSize: "12px",
                            lineHeight: "20px",
                            fontFamily: "Poppins",
                            letterSpacing: "-0.1px",
                          }}
                        >
                          Billed ${" "}
                          {plan.billingCycle === "Monthly"
                            ? plan?.recurringAmount
                            : plan?.oneTimeAmount}{" "}
                          every{" "}
                          {plan.billingCycle.toLowerCase() === "annually"
                            ? "year"
                            : "month"}
                          , starting today
                        </div>
                      </div>
                      <div className="w-2 h-2">
                        <img src={forward_arrow} className="" alt=">" />
                      </div>
                    </button>
                  ))
                ) : (
                  <p className="no-plans-message font-poppins">
                    No subscription plans available
                  </p>
                )}
              </div>
            </div>

            <div className="bg-[#EBECF0] mt-6 px-6">
              <p
                className="continiuep text-left font text-black text-[13px] font-medium"
                style={{ letterSpacing: "-0.45px" }}
              >
                By continuing, you are indicating that you accept our{" "}
                <a
                  href="https://onegoalfinance.com/terms"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Terms of Service
                </a>{" "}
                and{" "}
                <a
                  href="https://onegoalfinance.com/privacy-policy"
                  target="_blank"
                  className="underline font-poppins font-semibold text-[13px]"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalSubscription;
