// import { createSlice } from '@reduxjs/toolkit';

// const initialState = {
//   token: null,
// };

// const tokenSlice = createSlice({
//   name: 'token',
//   initialState,
//   reducers: {
//     setToken: (state, action) => {
//       state.token = action.payload;
//     },
//   },
// });

// export const { setToken } = tokenSlice.actions;

// export const selectToken = (state: { token: { token: any; }; }) => state.token.token;

// export default tokenSlice.reducer;



import { createAction, createSlice } from '@reduxjs/toolkit';

export const setToken = createAction<string>('token/set');

const tokenSlice = createSlice({
  name: 'token',
  initialState: null as string | null,
  reducers: {
    clearToken(state) {
      return null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setToken, (state, action) => {
      return action.payload;
    });
  },
});

export const { clearToken } = tokenSlice.actions;
export default tokenSlice.reducer;