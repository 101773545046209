//todo code
// import axios from "axios";
// import { API_CALL_TYPE } from "./Api";
// import { Config } from "../Utils/AppConstants";

// const CancelToken = axios.CancelToken;
// const axiosSource = CancelToken.source();

// const getGenericHeaders = (token) => {
//   return {
//     Accept: "application/json",
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + token,
//     X_ASA_version: Config.X_ASA_version, // Add ASA version to headers
//   };
// };

// const makeGetApiCall = (url, callback, errorCallBack, token) => {
//   const tempHeaders = getGenericHeaders(token); // Obtain headers with token
//   axios
//     .get(url, {
//       cancelToken: axiosSource.token,
//       headers: tempHeaders, // Use tempHeaders
//     })
//     .then((result) => callback(result.data))
//     .catch((error) => {
//       errorCallBack(error);
//     });
// };

// const makePostApiCall = (
//   url,
//   callback,
//   errorCallBack,
//   formData,
//   token,
//   body
// ) => {
//   const tempHeaders = getGenericHeaders(token); // Obtain headers with token

//   tempHeaders["Content-Type"] = "application/json";
//   axios
//     .post(url, body, {
//       cancelToken: axiosSource.token,
//       headers: tempHeaders, // Use tempHeaders
//     })
//     .then((result) => callback(result.data))
//     .catch((error) => {
//       errorCallBack(error);
//     });
// };

// export const makeApiCall = (
//   apiCallType,
//   urlPath,
//   callback,
//   errorCallBack,
//   formData,
//   token,
//   body = {}
// ) => {
//   if (apiCallType === API_CALL_TYPE.GET_CALL) {
//     makeGetApiCall(urlPath, callback, errorCallBack, token); // Pass token to makeGetApiCall
//   } else if (apiCallType === API_CALL_TYPE.POST_CALL) {
//     makePostApiCall(urlPath, callback, errorCallBack, formData, token, body); // Pass token to makePostApiCall
//   }
// };

import axios, { CancelTokenSource } from "axios";
import { API_CALL_TYPE } from "./Api";
import { Config } from "../Utils/AppConstants";
import { env } from '../Utils/env'
const CancelToken = axios.CancelToken;
const axiosSource: CancelTokenSource = CancelToken.source();

const getGenericHeaders = (token: string) => {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
     asaConsumerCode: Config.asaConsumerCode,
    X_ASA_version: Config.X_ASA_version,
    "Ocp-Apim-Subscription-Key": env.REACT_APP_SUBSCRIPTION,
    AsaFintechCode: Config.asaFicode,
   
  };
};

const makeGetApiCall = (
  url: string,
  callback: (data: any) => void,
  errorCallBack: (error: any) => void,
  token: string,
  form: any,
  body: any
) => {
  const tempHeaders = getGenericHeaders(""); // Obtain headers with token]

  axios
    .get(url, {
      
      headers: tempHeaders, // Use tempHeaders
      withCredentials: true, // Adding withCredentials: false
    })
    .then((result) => callback(result.data))
    .catch((error) => {
      errorCallBack(error);
    });
};

const makePostApiCall = (
  url: string,
  callback: (data: any) => void,
  errorCallBack: (error: any) => void,
  formData: any,
  token: string,
  body: any
) => {
  const tempHeaders = getGenericHeaders(token); // Obtain headers with token

  tempHeaders["Content-Type"] = "application/json";

  axios
    .post(url, body, {
      cancelToken: axiosSource.token,
      headers: tempHeaders, // Use tempHeaders
      withCredentials: true, // Adding withCredentials: false
    })
    .then((result) => callback(result.data))
    .catch((error) => {
      errorCallBack(error);
    });
};

const makePutApiCall = (
  url: string,
  callback: (data: any) => void,
  errorCallBack: (error: any) => void,
  formData: any,
  token: string,
  body: any
) => {
  const tempHeaders = getGenericHeaders(token);

  tempHeaders["Content-Type"] = "application/json";
  axios
    .put(url, body, {
      cancelToken: axiosSource.token,
      headers: tempHeaders,
      withCredentials: false,
    })
    .then((result) => callback(result.data))
    .catch((error) => {
      errorCallBack(error);
    });
};


const makeDeleteApiCall = (
  url: string,
  callback: (data: any) => void,
  errorCallBack: (error: any) => void,
  token: string,
  deleteData: any // Data payload for DELETE request
) => {
  const tempHeaders = getGenericHeaders(token);

  axios
    .delete(url, {
      headers: tempHeaders,
      data: deleteData, // Include data payload for DELETE
      cancelToken: axiosSource.token,
    })
    .then((result) => callback(result.data))
    .catch((error) => {
      errorCallBack(error);
    });
};


export const makeApiCall = (
  apiCallType: string,
  urlPath: string,
  callback: (data: any) => void,
  errorCallBack: (error: any) => void,
  formData: any,
  token: string,
  body: any = {}
) => {
  if (apiCallType === API_CALL_TYPE.GET_CALL) {
    makeGetApiCall(urlPath, callback, errorCallBack, formData, token, body); // Pass token to makeGetApiCall
  } else if (apiCallType === API_CALL_TYPE.POST_CALL) {
    makePostApiCall(urlPath, callback, errorCallBack, formData, token, body); // Pass token to makePostApiCall
  } else if (apiCallType === API_CALL_TYPE.PUT_CALL) {
    makePutApiCall(urlPath, callback, errorCallBack, formData, token, body);
  }
  else if (apiCallType === API_CALL_TYPE.DELETE_CALL) {
    
    makeDeleteApiCall(urlPath, callback, errorCallBack, token, body);
  }
  


};



