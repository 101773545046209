import React, { useState, useEffect } from "react";
import asavault_logo from "../assets/Images/asavaultlogo.png";
import { useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { SessionData } from "../types/types";
import { useSelector, useDispatch } from "react-redux";
import "../assets/css/style.css";
import { SessionState } from "../redux/sessionSlice";

const AuthenticationScreen = () => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const location = useLocation();
  const passedData = location.state;
  const navigate = useNavigate();
  const sessionData: SessionData | undefined =
    queryClient.getQueryData("sessionData");
  const updatedsessionData: SessionData | undefined =
    queryClient.getQueryData("updatedSessionData");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!sessionData) {
      console.log("Session data NOT available");
      navigate("/error", { state: passedData });
      // Session data not available, handle accordingly (e.g., navigate back to landing screen)
    } else {
      console.log("Session data available");
      // Session data available, perform validation
      const isValidSession = validateSession(sessionData);
      if (isValidSession) {
        console.log("Session is valid");
        // Session is valid, show loader for a few seconds before navigation
        setTimeout(() => {
          setLoading(false);
          navigate("/home", { state: passedData });
        }, 3000); // 3000 milliseconds = 3 seconds
      } else {
        console.log("Session is invalid");
        // Session is invalid, navigate to another screen
        navigate("/error", { state: passedData });
      }
    }
  }, [sessionData, dispatch]);

  // Function to validate the session data
  const validateSession = (sessionData: SessionData): boolean => {
    // Perform validation based on specific keys or properties
    // For example:
    // return sessionData.key === "someValue";
    return true;
  };

  return (
    <div className="flex bg-theme asavault_logo  flex-col items-center justify-center h-screen">
      <img src={asavault_logo} alt="ASA Vault Logo" className="h-16 mb-4" />
      <p className="text-base text-white poppins-medium mb-2">
        Authenticating...
      </p>
      {loading && (
        <div className="animate-spin h-8 w-8 border-t-2 border-b-2 border-white-900 rounded-full"></div>
      )}
    </div>
  );
};

export default AuthenticationScreen;
