// // sessionSlice.js
// import { createSlice, PayloadAction } from "@reduxjs/toolkit";

// interface PaymentCVV {
//   [paymentMethodId: string]: string | null;
// }

// export interface SessionState {
//   session: any;
//   sessionData: any;
//   consumerAddress: any;
//   selectedPaymentMethod: null;
//   selectedBillingAddress: null;
//   paymentList: any;
//   billAddressList: any;
//   isLoading: boolean;
//   error: string | null;
//   clickedHeader: null;
//   dynamicLink: string | null;
//   sessionID: string | null;
//   asaConsumerCode: string | null;
//   versionCode: number;
//   asaFicode: string | null;
//   xAsaVersion: number;
//   tokenStore: string | null;
//   newPaymentField: string | null;
//   paymentCVVs: PaymentCVV; // Object with paymentMethodId as key and CVV as value}
// }
// // interface RootState {
// //   session: {
// //     sessionData: any;
// //     isLoading: boolean;
// //     error: string | null;
// //   };
// //   // Add other slices if you have them
// // }

// const initialState = {
//   sessionData: null,
//   consumerAddress: null,
//   selectedPaymentMethod: null,
//   selectedBillingAddress: null,
//   paymentList: null,
//   billAddressList: null,
//   isLoading: false,
//   error: null,
//   clickedHeader: null, // Add a new field for clicked header
//   dynamicLink: null, // Initialize dynamicLink to null,
//   sessionIDStore: null,
//   asaConsumerCode: null,
//   versionCode: 1.06,
//   asaFicode: null,
//   xAsaVersion: 1.06,
//   tokenStore: null,
//   newPaymentField: null, // Add your new field here
//    paymentCVVs: {},
// };

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface PaymentCVV {
  [paymentMethodId: string]: string | null;
}

export interface SessionState {
  session: any;
  sessionData: any;
  consumerAddress: any;
  selectedPaymentMethod: any; // Adjust this type if possible
  selectedBillingAddress: any; // Adjust this type if possible
  paymentList: any;
  billAddressList: any;
  isLoading: boolean;
  error: string | null;
  clickedHeader: any; // Adjust this type if possible
  dynamicLink: string | null;
  sessionIDStore: string | null;
  sessionID: string | null;
  asaConsumerCode: string | null;
  versionCode: number;
  asaFicode: string | null;
  xAsaVersion: number;
  tokenStore: string | null;
  newPaymentField: string | null;
  paymentCVVs: PaymentCVV; // Object with paymentMethodId as key and CVV as value
}

const initialState: SessionState = {
  session: null, // Add this property to match the SessionState interface
  sessionData: null,
  consumerAddress: null,
  selectedPaymentMethod: null,
  selectedBillingAddress: null,
  paymentList: null,
  billAddressList: null,
  isLoading: false,
  error: null,
  clickedHeader: null,
  dynamicLink: null,
  sessionIDStore: null,
  sessionID: null,
  asaConsumerCode: null,
  versionCode: 1.06,
  asaFicode: null,
  xAsaVersion: 1.06,
  tokenStore: null,
  newPaymentField: null,
  paymentCVVs: {},
};

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setSessionData: (state, action) => {
      state.sessionData = action.payload;
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setDynamicLink: (state, action) => {
      state.dynamicLink = action.payload;
    },

    setClickedHeader: (state, action) => {
      state.clickedHeader = action.payload;
    }, // Add a new action to set clicked header

    setCOnsumerAdress: (state, action) => {
      state.consumerAddress = action.payload;
    },

    setSelectedPaymentMethod: (state, action) => {
      state.selectedPaymentMethod = action.payload;
    },
    setSelectedBillingAddress: (state, action) => {
      state.selectedBillingAddress = action.payload;
    },

    setPaymentList: (state, action) => {
      state.paymentList = action.payload;
    },
    setBillList: (state, action) => {
      state.billAddressList = action.payload;
    },

    setSessionIDStore: (state, action) => {
      state.sessionIDStore = action.payload;
    },
    setAsaConsumerCode: (state, action) => {
      state.asaConsumerCode = action.payload;
    },
    setVersionCode: (state, action) => {
      state.versionCode = action.payload;
      state.xAsaVersion = action.payload; // Update xAsaVersion as well
    },
    setAsaFicode: (state, action) => {
      state.asaFicode = action.payload;
    },
    setTokenStore: (state, action) => {
      state.tokenStore = action.payload;
    },
    setXAsaVersion: (state, action) => {
      state.xAsaVersion = action.payload;
    },
    setNewPaymentField: (state, action) => {
      state.newPaymentField = action.payload; // Add new action for new field
    },

    setPaymentCVV: (
      state,
      action: PayloadAction<{ paymentMethodId: number; cvv: string | null }>
    ) => {
      const { paymentMethodId, cvv } = action.payload;
      state.paymentCVVs[paymentMethodId] = cvv;
    },

    clearPaymentCVVs: (state) => {
      state.paymentCVVs = {}; // Reset paymentCVVs to an empty object
    },
  },
});

export const {
  setSessionData,
  setLoading,
  setError,
  setClickedHeader,
  setDynamicLink,
  setCOnsumerAdress,
  setSelectedPaymentMethod,
  setSelectedBillingAddress,
  setPaymentList,
  setBillList,
  setSessionIDStore,
  setAsaConsumerCode,
  setVersionCode,
  setAsaFicode,
  setTokenStore,
  setXAsaVersion,
  setNewPaymentField,
  setPaymentCVV,
  clearPaymentCVVs,
} = sessionSlice.actions;

export default sessionSlice.reducer;
