import React from "react";
import help from "../assets/Images/help.png";
import captive_portal from "../assets/Images/captive_portal.png";
import check from "../assets/Images/check.png";
const CreditRentBoost = () => {
  const handleBackButtonClick = () => {
    // Go back to the ASAVAULT APP using the dynamic link
    window.location.href = "https://asavault.page.link/PWATEST ";
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-theme p-4">
      <div className="rounded-full w-16 h-16 flex items-center justify-center">
        <img src={check} className="h-12 w-12" alt="Check Icon" />
      </div>

      <h2 className="text-white font-poppins font-bold text-2xl mt-6">
        Credit Rent Boost
      </h2>
      <p className="text-white font-poppins text-center mt-2">
        Has been added to your ASA profile.
      </p>
      <div className=" p-2 mt-6">
        <div className="rounded-full w-8 h-8 flex items-center justify-center">
          <img src={help} className="h-6 w-6" alt="Help Icon" />
        </div>
      </div>
      <p className="text-white font-poppins text-center ">
        There may be additional <br /> questions to ensure the <br /> best
        results for you.
      </p>
      <div className=" p-2 mt-6">
        <div className="rounded-full w-8 h-8 flex items-center justify-center">
          <img
            src={captive_portal}
            className="h-6 w-6"
            alt="Captive Portal Icon"
          />
        </div>
      </div>
      <p className="text-white font-poppins text-center ">
        We will also provide a <br /> portal for you to log into <br /> and see
        real-time updates.
      </p>
      {/* <Link to="/done" className="block text-center">
      <button className="fixed bottom-0 left-0 mx-auto w-full mb-4  text-white font-bold py-2 px-4 rounded-full">
        Close
      </button>
      </Link> */}

      <button
        onClick={handleBackButtonClick}
        className="fixed bottom-0 left-0 mx-auto w-full mb-4  text-white font-bold py-2 px-4 rounded-full"
      >
        Close
      </button>
    </div>
  );
};

export default CreditRentBoost;
