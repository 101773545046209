// PastRent.js

import React, { useState, useEffect } from "react";
import close_small from "../assets/Images/close_small.png";
import clock from "../assets/Images/clock.png";

interface PastRentProps {
  onCloseClick: () => void;
  onSelectOption: (option: number) => void;
  onPastRentClose: (amount: number) => void; // Function to update due amount in HomeScreen
  onAddToDue: (amount: number) => void; // Function to add an amount to due
  selectedOptionProp: number; // Prop for the selected option from the parent component
}

export default function PastRent({
  onCloseClick,
  onAddToDue,
  onSelectOption,
  onPastRentClose,
  selectedOptionProp, // Receive selected option as prop
}: PastRentProps) {
  const [selectedOption, setSelectedOption] = useState(selectedOptionProp); // Default selected option set to 12
  const [dueAmount, setDueAmount] = useState(49); // Default due amount for 12 months
    // const [selectedOption, setSelectedOption] = useState(selectedOptionProp); // Initialize selectedOption state with prop value
  const handleAddToDue = () => {
    // Add logic to calculate the amount
    const amount = 6.95; // Example amount
    onAddToDue(amount);
  };
  useEffect(() => {
    // Update due amount when the selected option changes
    if (selectedOption === 12) {
      setDueAmount(49);
    } else if (selectedOption === 24) {
      setDueAmount(65);
    }
  }, [selectedOption]);

  const handleOptionSelect = (option: number) => {
    setSelectedOption(option);
    onSelectOption(option); // Notify parent component about the selected option
  };

  const handleCloseClick = () => {
    // Calculate the new due amount based on the selected option
    let newAmount = selectedOption === 12 ? 49 : 65;
    onPastRentClose(newAmount); // Pass the new amount to the HomeScreen component
    onCloseClick(); // Close the PastRent component
  };

  return (
    <div
      className="w-[321px] relative mx-auto text-center flex flex-col shadow-lg rounded-xl p-4"
      style={{ backgroundColor: "#7b43de" }}
    >
      <img
        src={close_small}
        className="absolute top-1 left-1 w-12 h-12 p-2 rounded-full focus:outline-none focus:ring focus:border-blue-300"
        onClick={handleCloseClick}
        alt="Close"
      />
      <div className="flex flex-col space-y-4 pb-4">
        <div className="flex items-center mt-3 space-x-4 mx-auto">
          <img
            src={clock}
            className="w-[34px] h-[34px] object-contain"
            alt="Clock"
          />
          <p
            style={{ fontWeight: "" }}
            className="poppins-medium whitespace-nowrap h-[24px] text-[18px] font-poppins leading-8"
          >
            Past Rent Reporting
          </p>
        </div>

        <div className="flex mx-auto space-x-1">
          <strong className=" month-span-val poppins-bold ">
            ${dueAmount}
          </strong>
          <div className="flex flex-col">
            <p className="h-[8px]"></p>
            <span className="due-font poppins-medium">one-time</span>
          </div>
        </div>

        <div className="flex flex-col space-y-2">
          <div
            className={`flex items-center mx-auto font-poppins font-medium text-sm text-gray-200 justify-center space-x-3 cursor-pointer`}
            onClick={() => handleOptionSelect(12)}
          >
            <span className="past12 poppins-semibold">
              Past 12 months of rent reporting
            </span>
            <span
              className={`rounded-full border border-white h-4 w-4 ${
                selectedOption === 12 ? "bg-green-600" : "bg-white"
              }`}
            ></span>
          </div>

          <div
            className={` flex items-center  mx-auto font-poppins font-medium text-sm text-gray-200 justify-center space-x-3 cursor-pointer`}
            onClick={() => handleOptionSelect(24)}
          >
            <span className="past12 poppins-extralight mt-1 ">
              Past 24 months of rent reporting
              <strong className="block text-white text-left past12  poppins-extrabold">
                Best Value!
              </strong>
            </span>
            <span
              className={`rounded-full border border-white h-4 w-4 mb-2 ${
                selectedOption === 24 ? "bg-green-600" : "bg-white"
              }`}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
}
