export const Config = {
   API_URL: "https://api.asavault.com/",
  X_ASA_version: 1.08,
  sessionID: "",
  subscriptionKey: "",
  token: "",
  asaFicode: 12345679, // Declare type as string or null
  asaConsumerCodee: "",
  asaConsumerCode: 2037376870,
  dynamicLink: "",
  asaFintechCode: 12345679,



    AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
request=FintechBillingPage
&token=njhutsb
&asaFintechCode=618038590
&asaFintechApplicationCode=124
&asaConsumerCode=1143858721
&SubscriptionPlanCode=18
&IsNewSubscription=YES
&IsTrialEligible=NO
&Amount=125
&CouponCode=BUY2024
&PrevSubscriptionPlanCode=18
&redirecturl=https://portfoliopilot.com/asa/asa_landing?confirmation=&message=
&redirecturlonError=https://portfoliopilot.com/asa/asa_landing/error?message=Authentication%20Failed
`,

//    AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate?
// request=FintechBillingPage
// &asaFintechCode=618038590
// &asaConsumerCode=939168496
// &redirecturl=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences?sync=true
// &SubscriptionPlanCode=16
// &IsNewSubscription=YES&IsTrialEligible=YES&Amount=99.0&token=undefined&redirecturlonError=https://sandbox.portfoliopilot.com/track?onboarding=complete&view=Preferences
// `,




  
  // AUTHENTICATE_URL: `https://appdev.asavault.com/authenticate
// `,

  BROWSER_URL: 
"https://appdev.asavault.com/onegoal?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=684007338&asaFintechCode=1054208782&asaConsumerCode= 1143858721&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
//  "https://appdev.asavault.com/portfoliopilot?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2Vyc1ZhdWx0aWQiOiIzNDQ1IiwiVXNlck5hbWUiOiJneWFuZXNoLmthbWFsQGFzYS5maW5hbmNpYWwiLCJBc2FDb25zdW1lckNvZGUiOiIxNDIyNTMxODkiLCJUb2tlblRhZyI6IjIxNWM3ZDBjLTJhNDYtNGQ5My04OWU3LTViZjRjMTk1MzBhMyIsIkRldmljZUlkIjoiZjM2ZGQ2MWZlOTAyYWVlZCIsIkV4cGlyYXRpb25UaW1lIjoiMjAyNS0wMS0xN1QwNjoxNTozOS4zMDIzMzc5WiIsImp0aSI6IjQ2YmI3YTBhLWQwZTktNGNjOC1iNTMyLTc1NzkwNmI0NDYyYiIsIm5iZiI6MTcyMTU0MjUzOSwiZXhwIjoxNzM3MDk0NTM5LCJpYXQiOjE3MjE1NDI1MzksImlzcyI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSIsImF1ZCI6Imh0dHBzOi8vYXNhY29ubmVjdGRldi5hc2Fjb3JlLmNvbSJ9.dXgE19hmc2QVcKZKbAcbqUfcFd1qYALpMOOb7wcuNY0&sessionid=684007338&asaFintechCode=1282849331&asaConsumerCode= 1143858721&dynamicLink=https://asavault.page.link/eCKt7N8DCBYgTNaFA&version=1.08",
  rupeeSymbol: "\u20B9",
};

