import React, { useState, useEffect, useCallback } from "react";
import arrow_back_ios_new from "../assets/Images/arrow_back_ios_new.png";
import {
  API_CALL_TYPE,
  GET_BILLING_ADDRESSES,
  POST_CONSUMER_ADDRESS,
  UPDATE_SESSION,
} from "../services/Api";
import { makeApiCall } from "../services/ApiService";
import LoaderComponent from "../components/LoaderComponent";
import { ToastContentProps, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useQueryClient } from "react-query";
import "./Address.css"; // Import your CSS file for animations

import {
  setSessionData,
  setLoading,
  setError,
  SessionState,
  setCOnsumerAdress,
  setSelectedBillingAddress,
} from "../redux/sessionSlice";
import { Config } from "../Utils/AppConstants";
import { SessionUpdateRequest } from "../types/types";
interface BillingAddress {
  consumerAddressId: number;
  address1: string;
  address2: string | null;
  city: string;
  state: string;
  zipCode: string;
  firstName: string;
  lastName: string;
  country: string;
}
const BillingScreen = () => {
  const [inputs, setInputs] = useState({
    country: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    aptSuite: "",
    city: "",
    state: "",
    zipCode: "",
  });

  const [errors, setErrors] = useState({
    country: "",
    firstName: "",
    lastName: "",
    streetAddress: "",
    city: "",
    state: "",
    zipCode: "",
    aptSuite: "",
  });

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false); // State to control the visibility of the loader
  const [isFormFilled, setIsFormFilled] = useState(false);
  const location = useLocation();
  const { state } = location;
  const [billingAddresses, setBillingAddresses] = useState<BillingAddress[]>(
    []
  ); // State for billing addresses
  // Check if state is defined and extract the address object
  const address = state?.address;
  const navigate = useNavigate();
  const updatedsessionData: SessionUpdateRequest | undefined =
    queryClient.getQueryData("updatedSessionData");
  const handleInputChange = (e: { target: { id: any; value: any } }) => {
    const { id, value } = e.target;
    setInputs({ ...inputs, [id]: value });
    setErrors({ ...errors, [id]: "" }); // Clear error when user starts typing
  };
  const UpdatedLocalData = useSelector(
    (state: SessionState) => state?.session.consumerAddress
  );

  const UpdatedLocalData1 = useSelector(
    (state: SessionState) => state?.session.sessionData
  );

  const handleCancel = () => {
    setInputs({
      country: "",
      firstName: "",
      lastName: "",
      streetAddress: "",
      aptSuite: "",
      city: "",
      state: "",
      zipCode: "",
    });
    setErrors({
      country: "",
      firstName: "",
      lastName: "",
      streetAddress: "",
      city: "",
      aptSuite: "",
      state: "",
      zipCode: "",
    });

    navigate(-1);
  };

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };

  useEffect(() => {
    if (UpdatedLocalData) {
      const sessionPages = UpdatedLocalData?.data?.sessionPages;

      if (!sessionPages || sessionPages.length === 0) {
        // If sessionPages array is empty or not found, fetch PaymentMethodList from API
        // fetchPaymentMethods();
        // fetchBillingAddresses();
        return;
      }

      sessionPages.forEach((page: { pageName: string; pageData: string }) => {
        try {
          if (page.pageName === "SUBSCRIPTION PLAN") {
            const parsedPageData = JSON.parse(
              page?.pageData.replace(/\\/g, "")
            );

            if (parsedPageData) {
              if (parsedPageData?.asaFintechCode !== null) {
                const parsedFicode = parseInt(
                  parsedPageData?.asaFintechCode,
                  10
                );
                if (!isNaN(parsedFicode)) {
                  Config.asaFicode = parsedFicode;
                } else {
                  // Handle case where asaFicode is not a valid number
                  console.error("Invalid asaFicode:");
                }
              }
            }
          }
        } catch (error) {
          console.error(`Error processing page '${page.pageName}':`, error);
        }
      });

      Config.sessionID = UpdatedLocalData?.data?.asaSessionCode;
      Config.asaConsumerCode = parseInt(
        UpdatedLocalData?.data?.asaConsumerCode
      );

      const versionCode = UpdatedLocalData?.data?.version;
      Config.X_ASA_version = versionCode ? parseFloat(versionCode) : 1.08; // const asaFiCode = currentUrl.searchParams.get("asaFiCode");

      queryClient.setQueryData("sessionID", Config.sessionID);
    }
  }, []);

  useEffect(() => {
    setIsFormFilled(
      !!(
        inputs.country &&
        inputs.firstName &&
        inputs.lastName &&
        inputs.streetAddress &&
        inputs.city &&
        inputs.state &&
        inputs.zipCode
      )
    );
  }, [inputs]);

  const handleBackClick = () => {
    navigate("/ListPay"); // This will navigate back to the previous page in the browser history
  };

  useEffect(() => {
    if (address) {
      setInputs({
        ...inputs,
        country: address.country || "",
        firstName: address.firstName || "",
        lastName: address.lastName || "",
        streetAddress: address.address1 || "",
        aptSuite: address.address2 || "",
        city: address.city || "",
        state: address.state || "",
        zipCode: address.zipCode || "",
      });
    } else if (UpdatedLocalData) {
      setInputs({
        ...inputs,
        country: "US", // Replace with your hardcoded country value
        firstName: UpdatedLocalData?.data?.firstName || "",
        lastName: UpdatedLocalData?.data?.lastName || "",
      });
    }
  }, [address, UpdatedLocalData]);

  /*
 Calling Post Consumer Address API
*/
  const handleSave = async () => {
    // Implement validation checks here
    if (validateForm()) {
      try {
        setIsLoading(true);
        const requestData = [
          {
            consumerAddressId: address ? address.consumerAddressId : 0,
            address1: inputs.streetAddress,
            address2: inputs.aptSuite || null,
            city: inputs.city,
            state: inputs.state,
            zipCode: inputs.zipCode,
            isCurrent: false,
            isActive: true,
            moveoutDate: null,
            isBilling: true,
            Country: inputs.country,
            FirstName: inputs.firstName,
            LastName: inputs.lastName,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];
        const response = makeApiCall(
          API_CALL_TYPE.POST_CALL,
          POST_CONSUMER_ADDRESS(),
          handleConsumerResponse,
          handleConsumerError,
          "",
          "",
          requestData
        );
        // Optionally, reset form after successful save
        setInputs({
          country: "",
          firstName: "",
          lastName: "",
          streetAddress: "",
          aptSuite: "",
          city: "",
          state: "",
          zipCode: "",
        });
      } catch (error) {
        console.error("Error updating session:", error);
        throw error;
      }
    } else {
      console.log("Form validation failed.");
    }
  };

  const validateForm = () => {
    let valid = true;
    let newErrors: typeof errors = { ...errors };

    // Check for required fields
    (Object.keys(inputs) as Array<keyof typeof inputs>).forEach((key) => {
      if (!inputs[key] && key !== "aptSuite") {
        newErrors[key] = `${key} is required`;
        valid = false;
      }
    });

    // Check if zipCode is numeric
    if (inputs.zipCode && !/^\d+$/.test(inputs.zipCode)) {
      newErrors.zipCode = "Zip code must be numeric";
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };

  const handleUpdateSessionResponse = (response: SessionUpdateRequest) => {
    if (response && response?.status == "400") {
      // You can show a toast message to the user or navigate to an error page
      showCustomToast("Invalid session. Please start again.", true);
      navigate("/error", { state: "Invalid Session" });
    } else {
      // Handle other responses or success cases
      dispatch(setSessionData(response));
      setIsLoading(false);
      queryClient.setQueryData("updatedSessionData", response);
      navigate("/ListPay");
    }
  };

  // Handling Update Session Error

  const handleUpdateSessionError = (_error: any) => {
    setIsLoading(false);
    dispatch(setLoading(false));
  };

  const updateSessionBilling = async (
    updatedSessionData: SessionUpdateRequest,
    session_if_any: any,
    pageNumber: string,
    pageName: string,
    pageTitle: string,
    ssesim: string
  ) => {
    dispatch(setLoading(true));

    const sessionUpdateRequest: SessionUpdateRequest = {
      asaSessionCode: ssesim,
      asaConsumerCode: UpdatedLocalData?.data?.asaConsumerCode,
      version: Config.X_ASA_version.toString(),
      sessionName: "ASAVAULT-GOAL",
      pageCount: "0",
      status: "Incomplete", // Ensure the status field is a string,
      statusMessage: "INCOMPLETE",
      sessionPages: [
        {
          asaSessionCode: ssesim,
          pageNumber: pageNumber, // Assuming this is the second page
          pageName: pageName,
          pageTitle: pageTitle,
          pageDescription: pageTitle,
          pageUrl: "/Billing",
          pageStatus: "Completed",
          pageStatusMessage: "Billing",
          pageData: JSON.stringify(session_if_any), // Serialize the selected plan data
        },
      ],
    };

    try {
      const response = await makeApiCall(
        API_CALL_TYPE.PUT_CALL,
        UPDATE_SESSION(),
        handleUpdateSessionResponse,
        handleUpdateSessionError,
        "",
        "",
        sessionUpdateRequest
      );

      return response;
    } catch (error) {
      setIsLoading(false);
      dispatch(setError("Error updating session:"));
      console.error("Error updating session:", error);
      throw error;
    }
  };

  /*
 Handle Billing Address Responses.
*/

  const handleBillingAddressesResponse = async (data: any) => {
    const formattedBillingAddresses: BillingAddress[] = data?.data
      .filter((address: any) => address.isBilling === true)
      .map((address: any) => ({
        consumerAddressId: address.consumerAddressId,
        address1: address.address1,
        address2: address.address2,
        city: address.city,
        state: address.state,
        zipCode: address.zipCode,
        firstName: address.firstName,
        lastName: address.lastName,
        country: address.country,
      }));

    // Dispatch selected billing address
    if (formattedBillingAddresses.length === 1) {
      dispatch(setSelectedBillingAddress(formattedBillingAddresses[0]));
    } else {
      // If multiple addresses, find the matching address
      const matchingAddress = formattedBillingAddresses.find(
        (addr) => addr.consumerAddressId === address?.consumerAddressId
      );

      if (matchingAddress) {
        dispatch(setSelectedBillingAddress(matchingAddress));
      } else {
        const latestAddress =
          formattedBillingAddresses[formattedBillingAddresses.length - 1];
        dispatch(setSelectedBillingAddress(latestAddress));
      }
    }
    setBillingAddresses(formattedBillingAddresses);
    try {
      let dataToUpdate; // Declare variable to hold the data to be updated

      // Check if UpdatedLocalData contains data and use it if available
      if (UpdatedLocalData) {
        dataToUpdate = UpdatedLocalData1;
      }

      try {
        // Set loading state to true before making the API call
        await updateSessionBilling(
          dataToUpdate,
          formattedBillingAddresses,
          "7",
          "BillAddressList",
          "BillAddressList",
          dataToUpdate?.data?.asaSessionCode
        );
      } catch (error) {
        setIsLoading(false);
        console.error("Error updating session:", error);
        // Handle error here
      } finally {
        // Set loading state to false after API call is completed (whether success or error)
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error updating session:", error);
      // Handle error here
    } finally {
      // Set loading state to false after API call is completed (whether success or error)
    }
    setIsLoading(false); // Stop loading state
  };

  const handleApiError = (error: any) => {
    console.error("API Error: ", error);
    setIsLoading(false); // Stop loading state on error
  };

  const fetchBillingAddresses = useCallback(async () => {
    try {
      const response = makeApiCall(
        API_CALL_TYPE.GET_CALL,
        GET_BILLING_ADDRESSES(),
        handleBillingAddressesResponse,
        handleApiError,
        "",
        ""
      );
    } catch (error) {
      console.error("Error fetching payment methods:", error);
      setIsLoading(false); // Stop loading state on error
    }
  }, []);

  const handleConsumerResponse = async (response: any) => {
    // Handle success response

    setIsLoading(false);

    if (response && response?.status == "400") {
      // Handle the invalid session error here
      // You can show a toast message to the user or navigate to an error page
      showCustomToast(response?.data, true);
      fetchBillingAddresses();
    } else {
      showCustomToast(response?.message, false);
      fetchBillingAddresses();
    }
  };

  const handleConsumerError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, false);
  };

  return (
    <div className="bg-gradient-to-b from-[#705AE2] to-[#490C95] h-screen flex flex-col  text-center pt-10 relative">
      {isLoading && <LoaderComponent />}{" "}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick}
          />
        </div>
        <p className="custom-style-bugs text-center flex-grow">
          Add an Address
        </p>
        <div className="w-6 h-6 sm:w-8 sm:h-8 ml-5"></div>
      </div>
      <div className="w-full  bg-[#EBECF0] rounded-t-3xl shadow-md p-6 mt-10 more-rounded">
        <form className="mt-5">
          <div className="mb-10 relative border-black">
            <label
              className={`absolute transition-all font-poppins font-semibold duration-300 ease-in-out transform left-3 bg-[#EBECF0] px-1 ${
                inputs.country
                  ? "top-[-15px] text-[#000000] scale-75 label-shrink"
                  : errors.country
                    ? "top-[-10px] text-[#4E4E4E] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="country"
              style={{
                fontSize: errors.country
                  ? "1rem"
                  : inputs.country
                    ? "1.25rem"
                    : "1rem", // Font size based on input or error
              }}
            >
              Country
            </label>
            <input
              type="text"
              id="country"
              className={`mt-6 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-4 pr-3 ${
                inputs.country ? "text-[#4E4E4E]" : ""
              }`}
              placeholder=" " // Ensuring consistent placeholder size
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000", // Ensure border is black
                color: "#000000",
              }}
              value={inputs.country}
              onChange={handleInputChange}
            />
            {errors.country && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.country}
              </p>
            )}
          </div>
          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#EBECF0] px-1 font-poppins ${
                inputs.firstName
                  ? "-top-2.5 text-[#000000] scale-75 label-shrink"
                  : errors.firstName
                    ? "top-[-10px] text-[#4E4E4E] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="firstName"
              style={{
                fontSize: errors.firstName
                  ? "1rem"
                  : inputs.firstName
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
              }}
            >
              First Name
            </label>
            <input
              type="text"
              id="firstName"
              className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.firstName ? "text-[#4E4E4E]" : ""
              }`}
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000", // Black border color
                color: "#000000",
              }}
              value={inputs.firstName}
              onChange={handleInputChange}
            />
            {errors.firstName && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.firstName}
              </p>
            )}
          </div>

          <div className="mb-10  relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#EBECF0] px-1 font-poppins ${
                inputs.lastName
                  ? "-top-2.5 text-[#000000] scale-75 label-shrink"
                  : errors.lastName
                    ? "top-[-10px] text-[#4E4E4E] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="lastName"
              style={{
                fontSize: errors.lastName
                  ? "1rem"
                  : inputs.lastName
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
              }}
            >
              Last Name
            </label>
            <input
              type="text"
              id="lastName"
              className={`mt-1 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.lastName ? "text-[#4E4E4E]" : ""
              }`}
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000", // Black border color
                color: "#000000",
              }}
              value={inputs.lastName}
              onChange={handleInputChange}
            />
            {errors.lastName && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }}
              >
                {errors.lastName}
              </p>
            )}
          </div>

          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform label-left bg-[#EBECF0] font-poppins ${
                inputs.streetAddress
                  ? "-top-2.5 text-[#000000] scale-75 label-shrink left-[-10px]" // Move label further left when shrunk
                  : errors.streetAddress
                    ? "top-[-10px] text-[#4E4E4E] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="streetAddress"
              style={{
                fontSize: errors.streetAddress
                  ? "1rem"
                  : inputs.streetAddress
                    ? "1.25rem"
                    : "1rem",
                zIndex: 1,
                left: inputs.streetAddress ? "5px" : "", // Adjust left positioning when label is shrunk
              }}
            >
              Street Address
            </label>
            <input
              type="text"
              id="streetAddress"
              className={`mt-4 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.streetAddress ? "text-[#4E4E4E]" : ""
              }`}
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000",
                color: "#000000",
                paddingTop: "0.75rem", // Adjusted padding for vertical centering
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                lineHeight: "1.25rem", // Line height to help with vertical centering
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.streetAddress}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty
            />
            {errors.streetAddress && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }} // Adjust positioning for error message
              >
                {errors.streetAddress}
              </p>
            )}
          </div>

          <div className="mb-10 relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#EBECF0] font-poppins ${
                inputs.aptSuite
                  ? "-top-2.5 text-[#000000] scale-75 label-shrink left-[-10px]" // Move label further left when shrunk
                  : errors.aptSuite
                    ? "top-[-0.75rem] text-[#4E4E4E] -translate-y-1/2 label-expand"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="aptSuite"
              style={{
                fontSize: errors.aptSuite
                  ? "1rem"
                  : inputs.aptSuite
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
                zIndex: 1, // Ensure label stays above other elements
                left: inputs.aptSuite ? "-2px" : "", // Adjust left positioning when label is shrunk
              }}
            >
              Apt, Suite, etc. (optional)
            </label>
            <input
              type="text"
              id="aptSuite"
              className={`mt-4 block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.aptSuite ? "text-[#4E4E4E]" : ""
              }`}
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000", // Black border color
                color: "#000000",
                paddingTop: "0.75rem", // Adjusted padding to center text vertically
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.aptSuite}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty
            />
            {errors.aptSuite && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px" }} // Adjust positioning for error message
              >
                {errors.aptSuite}
              </p>
            )}
          </div>

          <div className="mb-10  relative">
            <label
              className={`absolute transition-all duration-300 ease-in-out transform left-2 bg-[#EBECF0] px-2 font-poppins ${
                inputs.city
                  ? "-top-2.5 text-[#000000] scale-75 label-shrink"
                  : errors.city
                    ? "top-[-0.75rem] text-[#4E4E4E] -translate-y-1/2 label-expand" // Adjusted position when there's an error
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
              }`}
              htmlFor="city"
              style={{
                fontSize: errors.city
                  ? "1rem"
                  : inputs.city
                    ? "1.25rem"
                    : "1rem", // Font size based on error or input state
                zIndex: 1, // Ensure label stays above other elements
              }}
            >
              City
            </label>
            <input
              type="text"
              id="city"
              className={`block w-full h-[55px] font-poppins rounded-md border-2 border-black shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                inputs.city ? "text-[#4E4E4E]" : ""
              }`}
              style={{
                backgroundColor: "#EBECF0",
                borderColor: "#000000", // Black border color
                color: "#000000",
                paddingTop: "0.75rem", // Adjusted padding for vertical centering
                paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                lineHeight: "1.25rem", // Line height to help with vertical centering
                boxSizing: "border-box", // Includes padding and border in the element's total width and height
              }}
              value={inputs.city}
              onChange={handleInputChange}
              placeholder=" " // Placeholder should be empty to prevent extra space
            />
            {errors.city && (
              <p
                className="text-red-500 text-left text-xs absolute"
                style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
              >
                {errors.city}
              </p>
            )}
          </div>

          <div className="flex mb-10 space-x-4">
            <div className="flex-1 relative">
              <label
                className={`absolute transition-all duration-300 ease-in-out transform left-3 bg-[#EBECF0] px-1 font-poppins ${
                  inputs.state
                    ? "-top-2.5 text-[#000000] scale-75 label-shrink"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
                }`}
                htmlFor="state"
                style={{
                  fontSize: inputs.state ? "1.25rem" : "0.875rem",
                  color: inputs.state ? "#000000" : "#4E4E4E",
                  zIndex: 1, // Ensure label stays above other elements
                }}
              >
                State
              </label>
              <input
                type="text"
                id="state"
                className={`block w-full h-[55px] font-poppins rounded-md border-2 border-[#EBECF0] shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                  inputs.state && "text-[#4E4E4E] border-black"
                }`}
                style={{
                  backgroundColor: "#EBECF0",
                  borderColor: "#000000",
                  color: "#000000",
                  paddingTop: "0.75rem", // Adjusted padding for vertical centering
                  paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                  lineHeight: "1.25rem", // Line height to help with vertical centering
                  boxSizing: "border-box", // Includes padding and border in the element's total width and height
                }}
                value={inputs.state}
                onChange={handleInputChange}
                placeholder=" " // Placeholder should be empty to prevent extra space
              />
              {errors.state && (
                <p
                  className="text-red-500 text-left text-xs absolute"
                  style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
                >
                  {errors.state}
                </p>
              )}
            </div>

            <div className="flex-1 relative">
              <label
                className={`absolute transition-all duration-300 ease-in-out transform left-3 bg-[#EBECF0] px-1 font-poppins ${
                  inputs.zipCode
                    ? "-top-2.5 text-[#000000] scale-75 label-shrink"
                    : "top-1/2 text-[#4E4E4E] -translate-y-1/2 label-expand"
                }`}
                htmlFor="zipCode"
                style={{
                  fontSize: inputs.zipCode ? "1.25rem" : "0.875rem",
                  color: inputs.zipCode ? "#000000" : "#4E4E4E",
                  zIndex: 1, // Ensure label stays above other elements
                }}
              >
                Zip Code
              </label>
              <input
                type="tel"
                id="zipCode"
                className={`block w-full h-[55px] font-poppins rounded-md border-2 border-[#EBECF0] shadow-sm focus:border-black focus:ring-black sm:text-sm pt-2 pb-2 pl-3 pr-3 ${
                  inputs.zipCode && "text-[#4E4E4E] border-black"
                }`}
                style={{
                  backgroundColor: "#EBECF0",
                  borderColor: "#000000",
                  color: "#000000",
                  paddingTop: "0.75rem", // Adjusted padding for vertical centering
                  paddingBottom: "0.75rem", // Ensures equal padding on top and bottom
                  lineHeight: "1.25rem", // Line height to help with vertical centering
                  boxSizing: "border-box", // Includes padding and border in the element's total width and height
                }}
                value={inputs.zipCode}
                onChange={handleInputChange}
                placeholder=" " // Placeholder should be empty to prevent extra space
              />
              {errors.zipCode && (
                <p
                  className="text-red-500 text-left text-xs absolute"
                  style={{ left: "10px", bottom: "-1.5rem" }} // Adjust positioning for error message
                >
                  {errors.zipCode}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between items-center mb-2">
            <div className="flex justify-center items-center w-full mb-4">
              <button
                type="button"
                onClick={handleCancel}
                className="font-poppins text-base font-medium leading-5 tracking-tighter text-center"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  letterSpacing: "-0.1px",
                  textAlign: "center",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4 w-full">
            <div
              onClick={handleSave}
              className={`poppins-bold h-[51px] p-4 w-full mx-4 bg-[#705AE2] text-white text-center rounded-md shadow-md cursor-pointer font-poppins ${
                !isFormFilled && "saveblur"
              }`}
              style={{
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                fontSize: "18px",
              }}
            >
              {address ? "UPDATE" : "SAVE"}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BillingScreen;
